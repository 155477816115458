import React from 'react';
import PageHeader from "../components/PageHeader";
import About from "../components/About/home-two";
import Services from "../components/Services";
import CallToAction from "../components/CallToAction";

import ServiceThumb from '../assets/img/about.jpg'
import Layout from '../components/Layout';
import { Helmet } from "react-helmet";
import { WesiteTitle } from '../helpers/constants';

const PageAbout = () => {
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{WesiteTitle} :: About</title>
      </Helmet>
      <PageHeader
        bgImg={require('../assets/img/page-header.jpg')}
        title="ABOUT US"
        content="AKSSON always try to provide the best Business Solutions for Clients to grow up their Business very sharply and smoothly."
      />
      <About
        title={'Our Team'}
        heading="Meet Our <br/> Expert Member"
        thumb={ServiceThumb}
        content="<b>AKSSON</b> always try to provide the best Business Solutions for Clinets to grow up their Business very sharply and smoothly. We voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt."
      />
      <Services classes={'sp-y'} />
      {/* <Features classes={'sm-top sm-y'}/> */}
      {/* <Testimonial/>
        <Team/>
        <BrandLogo/> */}
      {/* <Funfact classes="sp-top"/> */}
      <CallToAction />
    </Layout>
  );
};

export default PageAbout;