import React from 'react';

import Slider from '../components/Slider/home-two'
import About from '../components/About/home-two'
import Services from '../components/Services'
import Features from '../components/Features'
// import Testimonial from "../components/Testimonials/home-two";
// import Team from "../components/Team/home-two";
// import Blog from "../components/Blog";
// import BrandLogo from "../components/BrandLogo";
// import Funfact from "../components/Funfact";
import CallToAction from "../components/CallToAction";
// import MobileMenu from "../components/MobileMenu";
import Layout from '../components/Layout';
import { Helmet } from "react-helmet";
import { WesiteTitle } from '../helpers/constants';

const HomeTwo = () => {
    return (
      <Layout>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{WesiteTitle} :: Home</title>
        </Helmet>
        <Slider/>
        <About/>
        <Services/>
        <Features classes="sp-y"/>
        {/* <Testimonial/>
        <Team/> */}
        {/* <Blog/> */}
        {/* <BrandLogo/>
        <Funfact/> */}
        <CallToAction/>
      </Layout>
    );
};

export default HomeTwo;