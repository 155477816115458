import React from 'react';

const FormInput = ({tag,type,name,placeholder,classes, onChangeEvent, required = false}) => {
    return (
        <div className="single-input-item">
            <label>
                {(()=>{
                    if(tag === 'input'){
                        return (
                          <input
                            type={type}
                            name={name}
                            placeholder={placeholder}
                            className={classes}
                            required
                            onChange={eve => {
                              if (!onChangeEvent) return;
                              onChangeEvent(name, eve.target.value)
                            }}/>
                        )
                    }else if(tag === 'textarea'){
                        return  (
                          <textarea
                            name={name}
                            cols="30" rows="7"
                            placeholder={placeholder}
                            className={classes}
                            required
                            onChange={eve => {
                              if (!onChangeEvent) return;
                              onChangeEvent(name, eve.target.value)
                            }}
                          />
                        )
                    }else if(tag === 'button'){
                        return (
                          <button
                            className={`btn-outline ${classes}`}
                          >
                            Send Message
                          </button>
                        )
                    }
                })()}
            </label>
        </div>
    );
};

export default FormInput;