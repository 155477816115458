import React from 'react';

const CareersTable = ({ headers, data }) => {
  return (
    <div className='table-container'>
      <table className='table'>
        <thead>
          {headers.map(header => {
            return (
              <tr>
                {header.map(head => {
                  return <th className='table-th'>{head.title}</th>
                })}
              </tr>
            );
          })}
        </thead>
        <tbody>
          {data.map(dataObj => {
            return (
              <tr className='table-body-tr'>
                {headers[0].map(head => {
                  if (head.key === 'view') {
                    return <td className='td-view'><a href={`/careers/${dataObj.positionTitle}?positionId=${dataObj.positionId}`}>{dataObj[head.key]}</a></td>
                  }
                  return <td className='table-td'>{dataObj[head.key]}</td>
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

export default CareersTable;