import React, { Fragment } from 'react';
import Footer from '../Footer';
import Header from '../Header';
import MobileMenu from '../MobileMenu';

const Layout = props => {
  return (
    <Fragment>
      <Header />
      {props.children}
      <Footer />
      <MobileMenu />
    </Fragment>
  )
}

export default Layout;