import React, { useEffect, useState, useRef } from 'react';
import FormInput from '../UI/Input';
import emailjs from '@emailjs/browser';

const ApplicationForm = () => {

  const [formDetails, setFormDetails] = useState({
    first_name: '',
    last_name: '',
    email_address: '',
    phone_no: '',
    visa_type: '',
    cover_letter: ''
  });

  const handleFormChange = (key, value) => {
    const clonedForm = {...formDetails};
    clonedForm[key] = value;
    setFormDetails(clonedForm);
  }

  const validateForm = () => {

  }

  useEffect(() => {
    console.log(formDetails)
  }, [formDetails])

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_6hym61k', 'template_wupii3y', form.current, 'XT1tuKCdwO_x28QZC')
      .then((result) => {
        console.log(result.text);
      }, (error) => {
        console.log(error.text);
      });
  };

  return (
    <div className='application-form'>
      <form ref={form} onSubmit={sendEmail}>
        <div className='apply-title'>Apply for this Job</div>
        <div className="col-md-12" style={{ paddingLeft: '0'}}>
          <FormInput
            tag={'input'}
            type={'text'}
            name={'first_name'}
            placeholder={'First Name *'}
            classes={'applicationForm-input'}
            onChangeEvent={handleFormChange}
            required
          />
        </div>
        <div className="col-md-12" style={{ paddingLeft: '0'}}>
          <FormInput
            tag={'input'}
            type={'text'}
            name={'last_name'}
            placeholder={'Last Name *'}
            onChangeEvent={handleFormChange}
            required
          />
        </div>
        <div className="col-md-12" style={{ paddingLeft: '0'}}>
          <FormInput
            tag={'input'}
            type={'email'}
            name={'email_address'}
            placeholder={'Email address *'}
            onChangeEvent={handleFormChange}
            required
          />
        </div>
        <div className="col-md-12" style={{ paddingLeft: '0'}}>
          <FormInput
            tag={'input'}
            type={'text'}
            name={'phone_no'}
            placeholder={'Phone No'}
            onChangeEvent={handleFormChange}
            required
          />
        </div>
        <div className="col-md-12" style={{ paddingLeft: '0'}}>
          <FormInput
              tag={'input'}
              type={'text'}
              name={'visa_type'}
              placeholder={'Visa Type *'}
              onChangeEvent={handleFormChange}
              required
          />
        </div>
        <div className='col-md-12' style={{ paddingLeft: '0'}}>
          <div className='apply-upload-resume-text'>Upload Resume</div>
          <input
            type="file"
            name="resume"
            className='apply-upload-resume'
            onChange={eve => {
              console.log(eve.target.files[0]);
            }}
            required
            accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
          />
        </div>
        <div className='col-md-12' style={{ paddingLeft: '0'}}>
          <FormInput
            tag={'textarea'}
            name={'cover_letter'}
            placeholder={'Summary/ Cover letter'}
            onChangeEvent={handleFormChange}
          />
        </div>
        <div className='col-md-12 apply-agree'>
          <input type="checkbox" name="agree" className='apply-input-check'/>
          <div>I am bound by the terms of the Service I accept Privacy Policy</div>
        </div>
        <div className='col-md-12' style={{ paddingLeft: '0'}}>
          <button
            className='btn-outline'
            style={{ width: '100%'}}
            type="submit"
          >
            SEND EMAIL
          </button>
        </div>
      </form>
    </div>
  )
}

export default ApplicationForm;