import React from 'react';
import Text from "../UI/Text";
import Widget from "../UI/Widget";
import List from "../UI/List";
import LI from "../UI/List/Item";
import {Link} from "react-router-dom";
import Logo from '../../assets/img/logo-dark.png'
import { address, facebook, instagam, linkedin, mobileNumber, twitter, webisiteURI, WesiteTitle } from '../../helpers/constants';

function Footer() {
    return (
        <footer className="footer-area sp-bottom">
            <div className="container">
                <div className="row mtn-40">
                    <div className="col-lg-4 order-4 order-lg-0">
                        <div className="widget-item">
                            <div className="about-widget">
                                <Link to={`${process.env.PUBLIC_URL + "/"}`}>
                                    <img src={Logo} alt="Logo"/>
                                </Link>

                                <Text>
                                In a World of Technology, Aksson Makes the Difference.
                                </Text>

                                <Text classes="copyright-txt">
                                    &copy; {new Date().getFullYear()} {WesiteTitle}. All Rights Reserved.
                                </Text>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4 col-lg-2 ml-auto">
                        <Widget title="Information">
                            <List classes="widget-list">
                                <LI><Link to={`${process.env.PUBLIC_URL + "/about"}`}>Our company</Link></LI>
                                <LI><Link to={`${process.env.PUBLIC_URL + "/contact"}`}>Contact us</Link></LI>
                                <LI><Link to={`${process.env.PUBLIC_URL + "/services"}`}>Our services</Link></LI>
                                <LI><Link to={`${process.env.PUBLIC_URL + "/"}`}>Careers</Link></LI>
                            </List>
                        </Widget>
                    </div>

                    <div className="col-md-4 col-lg-2 ml-auto">
                        <Widget title="Social Links">
                            <List classes="widget-list">
                                <LI><a rel="noopener noreferrer" href={facebook.url} target={'_blank'}>Facebook</a></LI>
                                <LI><a rel="noopener noreferrer" href={twitter.url} target={'_blank'}>Twitter</a></LI>
                                <LI><a rel="noopener noreferrer" href={linkedin.url} target={'_blank'}>LinkedIn</a></LI>
                                <LI><a rel="noopener noreferrer" href={instagam.url} target={'_blank'}>Instagram</a></LI>
                            </List>
                        </Widget>
                    </div>

                    <div className="col-md-4 col-lg-3">
                        <Widget title="Contact Us">
                            <address>
                                {address.address1} {address.address2} {address.city} {address.state} {address.zip}. <br />
                                <a href={`https://www.${webisiteURI}`} target='_blank' rel="noopener noreferrer">
                                    https://www.{webisiteURI}
                                </a><br />
                                {mobileNumber}
                            </address>
                        </Widget>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;