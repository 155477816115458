import React, { useState, useRef } from 'react';
import FormInput from "../../components/UI/Input";

import emailjs from '@emailjs/browser';

const From = () => {
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_6hym61k', 'template_daige5p', form.current, 'XT1tuKCdwO_x28QZC')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
    };


    const [toSend, setToSend] = useState({
        first_name: "",
        last_name: "",
        email_address: "",
        phone_no: "",
        con_message: "",

    });

    const handleChange = (e) => {
        setToSend({ ...toSend, [e.target.name]: e.target.value });
    };
    return (
        <div className="contact-form-wrap">
            <form id="contact-form" ref={form} onSubmit={sendEmail} >
                <div className="row">
                    <div className="col-md-6">
                        <FormInput
                            tag={'input'}
                            type={'text'}
                            name={'first_name'}
                            placeholder={'First Name *'}
                            onChangeEvent={handleChange}
                            value={toSend.first_name}
                            onChange={handleChange}
                        />
                    </div>

                    <div className="col-md-6">
                        <FormInput
                            tag={'input'}
                            type={'text'}
                            name={'last_name'}
                            value={toSend.last_name}
                            placeholder={'Last Name *'}
                            onChangeEvent={handleChange}
                        />
                    </div>

                    <div className="col-md-6">
                        <FormInput
                            tag={'input'}
                            type={'email'}
                            name={'email_address'}
                            value={toSend.email_address}
                            placeholder={'Email address *'}
                            onChangeEvent={handleChange}
                        />
                    </div>

                    <div className="col-md-6">
                        <FormInput
                            tag={'input'}
                            type={'text'}
                            name={'phone_no'}
                            value={toSend.phone_no}
                            placeholder={'Phone No'}
                            onChangeEvent={handleChange}
                        />
                    </div>

                    <div className="col-12">
                        <FormInput
                            tag={'textarea'}
                            name={'con_message'}
                            value={toSend.con_message}
                            placeholder={'Write Your Message *'}
                            onChangeEvent={handleChange}
                        />

                        <FormInput
                            tag={'button'}
                            classes={'btn-outline'}
                        />

                        <div className="form-message"/>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default From;