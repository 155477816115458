import React from 'react';
import Layout from '../components/Layout';
import CareersTable from '../components/CareersTable';
import headers from '../data/Careers/CareerHeaders.json';
import careersInfo from '../data/Careers/Careers.json';
import { Helmet } from 'react-helmet';
import { WesiteTitle } from '../helpers/constants';

const Careers = () => {

    const Header = () => {
      return (
        <div className='careers-header'>
          <span>Careers</span>
        </div>
      )
    }

    return (
      <Layout>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{WesiteTitle} :: Careers</title>
        </Helmet>
        {Header()}
        <CareersTable headers={headers} data={careersInfo}/>
      </Layout>
    );
};

export default Careers;