import React from 'react';
import ContactPage from "../templates/Contact";
import CallToAction from "../components/CallToAction";
import Layout from '../components/Layout';
import { Helmet } from 'react-helmet';
import { WesiteTitle } from '../helpers/constants';


const PageContact = () => {
    return (
      <Layout>
         <Helmet>
          <meta charSet="utf-8" />
          <title>{WesiteTitle} :: Services</title>
        </Helmet>
        <ContactPage/>
        <CallToAction/>
      </Layout>
    );
};

export default PageContact;