export const mobileNumber = '+1 9452674896';
export const noReplayMail = 'info@aksson.com'
export const infoEmail = 'info@aksson.com'
export const webisiteURI = 'akssoninc.com';
export const WesiteTitle = 'AKSSON Inc'

export const facebook = {
  url: 'https://www.facebook.com/AkssonInc'
}
export const twitter = {
  url: 'https://twitter.com/aksson_inc'
}
export const instagam = {
  url: 'https://www.instagram.com/akssoninc/'
}
export const linkedin = {
  url: 'https://www.linkedin.com/in/aksson-inc/'
}
// keep ','(cama) at end of address string
export const address = {
  address1: '6136 Frisco Square Boulevard ,',
  address2: 'Suite 400 - #314,',
  state: 'TX,',
  city: 'Frisco,',
  zip: '75034',
  country: 'USA'
}
