import React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Helmet } from 'react-helmet';
import { WesiteTitle } from '../helpers/constants';
import CareersData from '../data/Careers/Careers.json';
import Layout from '../components/Layout';
import ApplicationForm from '../components/ApplicationForm';

const postsData = {
  'SE01': ['Job Description', 'Skills Requirement'],
  'SE02': ['Job Responsibilities', 'Qualifications'],
  'SE03': ['Responsibilities', 'Job Description'],
  'SE04': ['Responsibilities'],
  'SE05': ['Responsibilities'],
  'SE06': ['Responsibilities', 'Description', 'Must Have Skills'],
  'SE07': ['Must Have Skills', 'Nice to Have Skills'],
  'SE08': ['Required Skills'],
  'SE09': ['Responsibilities', 'Skills - Experience and Requirements']
}

const JobDetails = props => {

  const { positionTitle } = useParams();
  const location = useLocation();
  const search = new URLSearchParams(location.search).get("positionId");

  const jobInfo = CareersData.find(job => job.positionId === search);


  const Header = () => {
    return (
      <div className="jobdetail-header">
        <div className='job-title'>{positionTitle}</div>
        <div className='job-quote'>Best-in-class design and development team.</div>
      </div>
    )
  }

  const renderDetails = () => {
    return (
      <div className='jobdetails-left'>
        {postsData[jobInfo.positionId].map(datakey => {
          return (
            <div>
              <div className='job-info-title'>{datakey}</div>
              <div>
                {jobInfo[datakey].map(point => {
                  return (
                    <div className='point-wrapper'>
                      <div className='tick-wrapper'>
                        <FontAwesomeIcon icon={solid('check')}/>
                      </div>
                      <div className='job-info-point'>{point}</div>
                    </div>
                  )
                })}
              </div>
            </div>
          )
        })}
      </div>
    )
  }

  const renderApplicationForm = () => {
    return (
      <ApplicationForm />
    )
  }

  const renderBody = () => {
    return (
      <div className='jobdetails-body'>
        {renderDetails()}
        {renderApplicationForm()}
      </div>
    )
  }

  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{WesiteTitle} :: Careers</title>
      </Helmet>
      {Header()}
      {renderBody()}
    </Layout>
  )
}

export default JobDetails;