import React from 'react';
import PageHeader from "../components/PageHeader";
import PageAbout from "../components/About/page";
import Services from "../components/Services";
import CallToAction from "../components/CallToAction";

import ServiceThumb from '../assets/img/about.jpg'
import Layout from '../components/Layout';
import { Helmet } from 'react-helmet';
import { WesiteTitle } from '../helpers/constants';

const PageService = () => {
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{WesiteTitle} :: Services</title>
      </Helmet>
      <PageHeader
        bgImg={require('../assets/img/page-header.jpg')}
        title="OUR SERVICES"
        content="AKSSON always try to provide the best Business Solutions for Clients to grow up their Business very sharply and smoothly."
      />
      <PageAbout
        title={'Our Services'}
        heading="Provide best <br/> Business Solutions"
        thumb={ServiceThumb}
        content="<b>AKSSON</b> always try to provide the best Business Solutions for Clinets to grow up their Business very sharply and smoothly. We voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt."
      />
      <Services classes="sm-top sp-y" />
      {/* <PricingTable/>
          <Testimonial/>
          <BrandLogo/> */}
      {/* <Funfact classes="sp-top"/> */}
      <CallToAction />
    </Layout>
  );
};

export default PageService;